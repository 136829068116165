<template>
  <div>
    <list
      routes-prefix="admin-"
      permission-prefix="global-"
      :is-system-admin="true"
    />
  </div>
</template>
<script>
import list from '../list.vue'

export default {
  components: { list },

}
</script>
<style lang="">

</style>
